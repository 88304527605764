<template>
  <input
    ref="input"
    v-model="modelValue"
    :class="{ isError }"
    v-bind="props"
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    @wheel="if (type === 'number') $event.preventDefault();"
  />
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from "vue";

export type Props = {
  modelValue?: any;
  isError?: boolean;

  // HTML Input Attributes
  type?: InputTypeHTMLAttribute;
  placeholder?: string;
  autofocus?: boolean;
};

export type Emits = {
  "update:modelValue": [value: any];
};

const props = withDefaults(defineProps<Props>(), { type: "text", modelValue: undefined, placeholder: undefined });

const emits = defineEmits<Emits>();

const { modelValue } = useVModels(props, emits);

const input = ref();

useFocus(input, { initialValue: props.autofocus });

defineExpose({ input });
</script>

<style scoped lang="scss">
input {
  border-radius: 10px;
  padding-left: 12px;
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border);
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  flex-grow: 1;
  color: var(--gray-1);
  background: var(--white);
  transition: outline-offset 0.1s ease-in-out;

  &:hover {
    border-color: var(--border-hover);
  }

  &:focus {
    outline: 2px solid var(--border-focus);
    outline-offset: 2px;
  }

  &.isError {
    border-color: var(--danger);

    &:focus {
      border-color: var(--danger);
      outline: 2px solid var(--danger-light);
      z-index: 1;
    }
  }

  &:disabled {
    background: var(--surface-disabled);
    cursor: not-allowed;

    &:hover {
      border: 1px solid var(--gray-5);
    }
  }

  &::placeholder {
    color: var(--gray-4);
  }
}
</style>
